<button type="button" mat-icon-button (click)="shareUsing('facebook')" class="social-icon facebook">
    <mat-icon svgIcon="facebook"></mat-icon>
</button>
<button type="button" mat-icon-button (click)="shareUsing('twitter')" class="social-icon twitter">
    <mat-icon svgIcon="twitter"></mat-icon>
</button>
<button type="button" mat-icon-button (click)="shareUsing('mail')" class="social-icon mail">
    <mat-icon svgIcon="mail"></mat-icon>
</button>
<button type="button" mat-icon-button (click)="shareUsing('copy')" class="social-icon link">
    <mat-icon svgIcon="link"></mat-icon>
</button>
