<div class="grid-item">
  <div class="grid-content">
    <media-image
      mode="landscape"
      [src]="person.poster"
      size="medium"
      [alt]="person.name + ' image'"
      [link]="url.generate(person)"
    >
    </media-image>
  </div>
</div>
<div class="grid-label">
  <a class="title" [routerLink]="url.generate(person)">{{ person.name }}</a>
  <div class="subtitle">
    <known-for-widget [person]="person"></known-for-widget>
  </div>
<!--  <div class="subtitle">-->
<!--    <span *ngIf="title.certification" class="subtitle-item certification">{{-->
<!--      title.certification-->
<!--      }}</span>-->
<!--    <genre-widget-->
<!--      *ngIf="title.genres?.length"-->
<!--      class="subtitle-item"-->
<!--      [genres]="title.genres"-->
<!--      [limit]="2"-->
<!--      separator=", "-->
<!--    ></genre-widget>-->
<!--    <span *ngIf="title.year" class="subtitle-item last"-->
<!--    >{{ (title.is_series ? 'Series' : 'Movie') | trans }} ({{ title.year }})</span-->
<!--    >-->
<!--  </div>-->
</div>
