export const TITLE_SORT_OPTIONS = {
    'popularity': 'Popularity',
    'user_score': 'User Score',
    'release_date': 'Release Date',
    'budget': 'Budget',
    'revenue': 'Revenue',
    'runtime': 'Runtime',
    'language': 'Language',
    'created_at': 'Date Added',
    'certification': 'Certification',
};
