<mat-button-toggle-group [formControl]="modeControl">
  <mat-button-toggle value="portrait">
    <mat-icon svgIcon="grid-view"></mat-icon>
  </mat-button-toggle>
  <mat-button-toggle value="landscape">
    <mat-icon svgIcon="view-column"></mat-icon>
  </mat-button-toggle>
  <mat-button-toggle value="detail">
    <mat-icon svgIcon="view-list"></mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
